import React, { Component } from 'react';
import { TabBar } from 'antd-mobile';
import { inject, observer } from 'mobx-react';
import Router from 'next/router';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
const GuideTips = dynamic(() => import('./GuideTips.js'), { ssr: false });

@inject(`userStore`, 'uiStore')
@observer
class Navbar extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props;
    const key = this.getKey(pathname);
    this.state = {
      key,
      selectedTab: 'homepage'
    };
  }

  getKey = pathname => {
    if (!pathname) return '/';
    const [, first] = pathname.split('/');
    const key = '/' + first;
    return key;
  };

  componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps;
    const key = this.getKey(pathname);
    this.setState({ key });
  }

  generateNavBar = (pathname, key, className) => {
    if (!pathname) return null;
    const hasUnreadMsg = this.props.userStore.hasUnreadMsg;
    if (!pathname.includes(`/conversation`)) {
      return (
        <div className={className}>
          <TabBar tintColor="#ff8000">
            <TabBar.Item
              title="首页"
              key={key}
              icon={<i className="iconfont icon-home" />}
              selectedIcon={<i className="iconfont icon-home" />}
              selected={pathname === '/'}
              onPress={() => {
                Router.push('/');
              }}
            />

            <TabBar.Item
              title="估价"
              key={key}
              icon={<i className="iconfont icon-chepai" />}
              selectedIcon={<i className="iconfont icon-chepai" />}
              selected={pathname === '/vehicle-valuation'}
              onPress={() => {
                Router.push('/vehicle-valuation');
              }}
            />

            <TabBar.Item
              key={key}
              title={'发布'}
              icon={<i className="iconfont icon-plus-square-fill" />}
              onPress={() => {
                Router.push('/new/category');
              }}
            />

            <TabBar.Item
              title="消息"
              key={key}
              icon={<i className="iconfont icon-message" />}
              selectedIcon={<i className="iconfont icon-message" />}
              selected={pathname === '/message'}
              onPress={() => {
                Router.push('/message');
              }}
              badge={hasUnreadMsg}
            />

            <TabBar.Item
              title="我的"
              key={key}
              icon={<i className="iconfont icon-user" />}
              selectedIcon={<i className="iconfont icon-user" />}
              selected={pathname === '/me'}
              onPress={() => {
                Router.push('/me');
              }}
            />
          </TabBar>
          <div className="NavbarEmptyBlock" />
          <GuideTips
            style={{ left: 'auto', bottom: '46px' }}
            middle={true}
            content={'“发帖”点这里'}
            top={false}
            hidden={this.props.uiStore.shouldHiddenTips}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  render() {
    const { key } = this.state;
    const { pathname, className } = this.props;
    return this.generateNavBar(key, pathname, className);
  }
}

const Styled = styled(Navbar)`
  @supports (bottom: constant(safe-area-inset-bottom)) or
    (bottom: env(safe-area-inset-bottom)) {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
    .NavbarEmptyBlock {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
      background-color: #fff;
    }
  }
  @media (min-width: ${props => props.theme.desktopMinWidth}) {
    width: ${props => props.theme.maxWidth};
    margin: auto;
  }
  @media (max-width: ${props => props.theme.maxWidth}) {
    width: 100%;
  }
  position: fixed;
  //width: 100%;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  .iconfont {
    font-size: 30px;
  }
  .newPostIconContainer {
    position: fixed;
    z-index: 99;
    width: 20%;
    height: 60px;
    display: flex;
    justify-content: center;
  }
  .postButtonContainer {
    margin-top: -37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .postButton {
    width: 70px;
    height: 67px;
  }
  .postLabel {
    font-size: 12px;
    color: rgb(136, 136, 136);
  }
  .postIcon + .am-tab-bar-tab-title {
    margin-top: 10px;
  }

  .am-tab-bar {
    position: relative;
    z-index: 2;
    width: 100%;
  }
  .am-tab-bar-tab-title {
    margin: 2.5px 0;
    font-size: 12px;
  }
  .am-badge-dot {
    height: 12px;
    width: 12px;
  }
  .am-tab-bar-tab-icon .tab-dot :last-child {
    margin-top: 2px;
    left: 22px;
  }
  .am-tab-bar-tab:nth-child(3) {
    position: relative;
    top: -0.2em;
  }
  .am-tab-bar-tab:nth-child(3) .iconfont {
    color: ${props => props.theme.primaryColor};
    font-size: 36px;
  }
`;

export default Styled;
