import { ThemeProvider } from 'styled-components';
import '../App.less';
import theme from '../theme';
import PopupAdModal from '../components/PopupAdModal';
import React from 'react';
import App, { Container } from 'next/app';
import Head from 'next/head';
import { Provider } from 'mobx-react';
import Router from 'next/router';
import { initializeStore } from '../mobx/RootStore';
import ErrorBoundary from '../common/ErrorBoundary';
import NavBar from '../components/Navbar';
import Cookies from 'universal-cookie';
import { getSnapshot } from 'mobx-state-tree';
import * as Sentry from '@sentry/browser';
import pathToRegexp from 'path-to-regexp';
import * as gtag from '../lib/gtag';
import { getEnv, getFromLocal, setLocalStorage } from '../utilNoSSR';
import mitt from '../common/SMEvent.js';
import { EVENT } from '../common/SMEvent';
import api, { _axios } from '../api';
import moment from 'moment';

Router.events.on('routeChangeComplete', url => gtag.pageview(url));

const withBottomBar = [
  '/',
  '/index',
  '/me',
  '/message',
  '/vehicle-valuation',
  '/new/category',
  '/password/forgot',
  '/topicNotFound'
];

const withADPage = [
  '/',
  '/index',
  '/topic/[id]',
  '/car',
  '/rentalHomePage',
  '/ershou',
  '/jobHomePage',
  '/localServicesHomePage',
  '/generalHomePage/huodong',
  '/generalHomePage/shengyi',
  '/generalHomePage/lvyou',
  '/socialHomepage'
];

const privatePage = [
  '/authentication',
  '/auth/setPassword',
  '/merchantRegister',
  '/merchantRegister/step2',
  '/merchantRegister/step3',
  '/valuation-result',
  '/vehicle-Value',
  '/manual-valuation',
  '/topic/edit/[id]',
  '/merchant/:merchantId/edit',
  '/me/topics',
  '/me/merchant2',
  '/me/wallet',
  '/me/watchlist',
  '/message',
  '/conversation',
  '/order/payNotify',
  '/admin/simulate-login',
  '/group/myGroupList',
  '/group/members/[groupId]',
  '/group/shareGroup/[groupId]',
  '/group/selectGroup/',
  '/topic/:topicId/renew/result/:result',
  '/new/social/[id]',
  '/new/service/[id]',
  '/new/event/[id]',
  '/new/general-post/[id]',
  '/systemMessage',
  '/commentsMessage',
  '/verifyEmail',
  '/event/apply/[id]',
  '/event/apply/nzibes'
];

const sharedPage = [
  '/topic/[id]',
  '/pages/article/[id]',
  '/event/apply/[id]',
  '/event/apply/nzibes',
  '/merchant/[merchantId]'
];

const regexp = privatePage.map(path => pathToRegexp(path));
const regexpAD = withADPage.map(path => pathToRegexp(path));
const regexpShared = sharedPage.map(path => pathToRegexp(path));

const PSWP = () => {
  return (
    <div className="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="pswp__bg" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <div className="pswp__counter" />
            <button
              className="pswp__button pswp__button--close"
              title="Close (Esc)"
            />
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <button
            className="pswp__button pswp__button--arrow--left"
            title="Previous (arrow left)"
          />
          <button
            className="pswp__button pswp__button--arrow--right"
            title="Next (arrow right)"
          />
          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
    </div>
  );
};

const isServer = typeof window === 'undefined';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://064fe5f3e86d43bf9bf08cbeebf7ae6b@sentry.io/197679'
  });
}

class MyApp extends App {
  shareTitle = '找什么，神马都有';
  shareContent = '';
  shareImage = null;
  shareUrl = null;

  static async getInitialProps({ Component, ctx }) {
    const store = initializeStore(isServer);
    if (isServer) {
      //刷新页面，服务端对 userStore 进行初始化
      const query = ctx.query;
      const jwtFromWeChat = query ? query.jwt : null;
      const wxCode = query ? query.code : null;
      const { req } = ctx;
      if (req) {
        const cookie = new Cookies(req.headers.cookie);
        const jwt = jwtFromWeChat || cookie.get('jwt');

        if (wxCode) {
          await store.userStore.wxLogin(wxCode);
        } else if (jwt) {
          store.userStore.updateJWT(jwt);
          await store.userStore.getUserProfile();
        }
        await store.configStore.init();
      }
    }

    ctx.rootStore = store;
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    return { pageProps, initialState: getSnapshot(store) };
  }

  constructor(props) {
    super(props);
    this.store = initializeStore(isServer, props.initialState);
    this.state = {
      showPopupAdModal: false,
      popUpAdImage: '',
      popUpAdLink: '',
      popUpAdTuantuan: 0
    };
    this.wx = null;

    if (!isServer) {
      this.initApp();
    }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }

    super.componentDidCatch(error, errorInfo);
  }

  componentWillMount() {
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.href
    ) {
      window.entryUrl = window.location.href.split('#')[0];
    }
  }

  initShareContent = () => {
    this.shareTitle = '找什么，神马都有';
    this.shareContent = '';
    this.shareImage = null;
  };

  componentWillUnmount() {
    mitt.off(EVENT.SET_SHARE_CONTENT, () => {});
    mitt.off(EVENT.USER_STATUS_401, () => {});
  }

  getExclude = () => {
    const last = getFromLocal('popup_time');
    setLocalStorage('popup_time', moment().format('YYYY-MM-DD'));
    if (last) {
      const now = moment().format('YYYY-MM-DD');
      if (moment(now).isSame(last)) {
        return getFromLocal('exclude');
      } else {
        setLocalStorage('exclude', []);
        return null;
      }
    } else {
      setLocalStorage('exclude', []);
      return null;
    }
  };

  setExclude = id => {
    const ids = getFromLocal('exclude');
    if (ids) {
      setLocalStorage('exclude', [id, ...ids]);
    } else {
      setLocalStorage('exclude', [id]);
    }
  };

  async componentDidMount() {
    const route = this.props.router.route;
    //允许弹窗的页面
    const exclude = this.getExclude();
    if (regexpAD.some(reg => reg.test(route))) {
      const { data } = await api.Ad.popup({
        exclude: exclude
      });
      if (data && !data.code) {
        this.setExclude(data.id);
        const { link } = data;
        // 当前位于弹窗目标页
        if (
          link === Router.router.asPath ||
          (link.includes('/tuan/') && route === '/tuan')
        ) {
          return;
        }
        //当日尚未弹窗
        this.setState({
          popupId: data.id,
          showPopupAdModal: true,
          popUpAdImage: data.image,
          popUpAdLink: data.link || '',
          popUpAdTuantuan: data.tuantuan || 0
        });
      }
    }
  }

  initWeChatShare = () => {
    if (getEnv() !== 'wx' && getEnv() !== 'miniProgram') {
      return;
    }
    if (!window.WeixinJSBridge || !window.WeixinJSBridge.invoke) {
      document.addEventListener(
        'WeixinJSBridgeReady',
        () => {
          this.wxReady();
        },
        false
      );
    } else {
      this.wxReady();
    }
  };

  wxReady = async () => {
    if (!this.wx) {
      return;
    }
    //更新小程序的分享地址
    const that = this;
    this.wx.miniProgram.getEnv(function(res) {
      if (res.miniprogram) {
        that.wx.miniProgram.postMessage({
          data: {
            webViewUrl: that.shareUrl || window.location.href,
            title: that.shareTitle
          }
        });
      }
    });
  };

  async initApp() {
    //init wechat share
    this.wx = require('weixin-js-sdk');
    const route = this.props.router.route;
    this.initShareContent();
    if (!regexpShared.some(reg => reg.test(route))) {
      this.initWeChatShare();
    }
    //开启微信分享设置的监听
    mitt.on(EVENT.SET_SHARE_CONTENT, ({ url, title, content, image }) => {
      this.shareTitle = title;
      this.shareContent = content;
      this.shareImage = image;
      this.shareUrl = url;
      this.initWeChatShare();
    });
    mitt.on(EVENT.USER_STATUS_401, () => {
      this.store.userStore.logout();
      if (!isServer) {
        Router.push('/login');
      }
    });
    Router.events.on('routeChangeStart', url => {
      this.shareUrl =
        window.location.protocol + '//' + window.location.host + url;
      this.initShareContent();
    });
    Router.events.on('routeChangeComplete', r => {
      const route = this.props.router.route;
      if (regexpShared.some(reg => reg.test(route))) {
        return;
      }
      this.initWeChatShare();
    });
    //客户端初始化请求头
    let jwt = null;
    try {
      jwt = this.store.userStore.jwt || localStorage.getItem('jwt');
    } catch (e) {}
    if (jwt) {
      this.store.userStore.updateJWT(jwt);
      this.store.userStore.initUserStore();
    }
    const { gtag } = window;
    gtag('config', 'UA-101477606-1', { page_path: window.location.pathname });
  }

  render() {
    const { Component, pageProps } = this.props;
    const {
      showPopupAdModal,
      popUpAdImage,
      popUpAdLink,
      popUpAdTuantuan,
      popupId
    } = this.state;
    /** route intercept */
    const route = this.props.router.route;
    let childComponent = <Component {...pageProps} />;
    const jwt = this.store.userStore.jwt;
    if (
      !jwt &&
      regexp.some(reg => {
        return reg.test(route);
      })
    ) {
      // childComponent = <div>页面加载中...</div>;
      if (!isServer) {
        this.store.uiStore.requireLogin(route);
      }
    }
    /****** *******/
    return (
      <Container>
        <Head>
          <title>神马网，新西兰中文集市</title>
          <meta name="theme-color" content="#ee8100" />
          <meta name="apple-mobile-web-app-title" content="神马网" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="description"
            content="新西兰神马网是新西兰最大的中文分类信息网站，您可以免费查找新西兰最新最全的二手物品交易、二手车买卖、房屋租售、宠物、招聘、兼职、求职、交友活动、生活服务信息。还能免费发布这些信息。"
          />
          <link rel="manifest" href="/static/manifest.json" />
          <link rel="shortcut icon" href="/static/favicon.ico" />
          <link rel="stylesheet" href="/static/photoswipe/photoswipe.css" />
          <link
            rel="stylesheet"
            href="//at.alicdn.com/t/font_876372_fwsss5ktwi5.css"
          />
          <link
            rel="stylesheet"
            href="/static/photoswipe/default-skin/default-skin.css"
          />

          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
          />
          {getEnv() !== 'miniProgram' && typeof window !== 'undefined' && (
            <script src="https://js.stripe.com/v3/" />
          )}
          <noscript>You need to enable JavaScript to run this app.</noscript>
        </Head>
        <ErrorBoundary>
          <div
            className="main"
            style={
              withBottomBar.indexOf(route) > -1 ? { paddingBottom: 60 } : null
            }
          >
            <ThemeProvider theme={theme}>
              <Provider {...this.store}>
                <div>
                  {childComponent}
                  {withBottomBar.indexOf(route) > -1 && (
                    <NavBar pathname={route} />
                  )}
                  <PopupAdModal
                    popupId={popupId}
                    show={showPopupAdModal}
                    popUpAdImage={popUpAdImage}
                    link={popUpAdLink}
                    tuantuan={popUpAdTuantuan}
                    onClose={() => {
                      this.setState({ showPopupAdModal: false });
                    }}
                  />
                </div>
              </Provider>
            </ThemeProvider>
          </div>
        </ErrorBoundary>
        <PSWP />
      </Container>
    );
  }
}

export default MyApp;
